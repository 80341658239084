<template>
  <div></div>
</template>
<script>
import { setProjectCallback } from "@/api/user";
export default {
  data() {
    return {};
  },
  // s=6375114b-06b1-4f12-98a6-e31c4dba739a&id=27025241&sn=e9b954c426b3df9451c7545d68cf131d
  created() {
    const params = {
      id: null,
      requestType: null,
      sign: null,
      timestamp: null,
      traceId: null,
    };
    params.id = this.$route.query.id || null;
    params.sign = this.$route.query.sn || null;
    params.traceId = this.$route.query.s || null;
    setProjectCallback(params).then((res) => {
      if (res.status === 200) {
        location.href = res.data.returnUri;
      }
    });
  },
};
</script>